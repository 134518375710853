<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkDropdown from "@components/input/AkDropdown";
import AkInputText from "@components/input/AkInputText";
import AkInputNumber from "@components/input/AkInputNumber";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCondTravailService from "@services/bilanCondTravailService";
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import rolePermissionService from "@services/rolePermissionService";
import affectationService from "@services/affectationService";

export default {
  components: {AkFormView, AkFormSubmitted, AkDropdown, AkInputText, AkInputNumber},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan.create",
    }
  },
  data() {
    return {
      submitted: false,
      agerList: [],
      uerList: [],
      ceiList: [],
      disableAger: null,
      disableUer: null,
      disableCei: null,
      current: {
        ceiId: null,
        uerId: null,
        agerId: null,
      }
    }
  },
  validations() {
    return {
      current: {
        weekStart: {required},
        agerId: {required},
        uerId: {required},
        ceiId: {required}
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p4 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.healthcareReview.edit);

    let p5 = affectationService.findAllByUserId(this.currentUser.userId);
    p5.then(data => {
      this.affectations = data.affectations;
      this.agerList = data.agerList;
      if (this.agerList.length === 1) {
        this.current.agerId = this.agerList[0].id;
        this.disableAger = true;
      }

      this.uerList = data.uerList;
      if (this.uerList.length === 1) {
        this.current.uerId = this.uerList[0].id;
        this.disableUer = true;
      }

      this.ceiList = data.ceiList;
      if (this.ceiList.length === 1) {
        this.current.ceiId = this.ceiList[0].id;
        this.disableCei = true;
      }
    });
    Promise.all([p4, p5]).then(()=>{
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.create"));
        return;
      }
      
      if (!this.current.weekEnd) {
        this.current.weekEnd = this.current.weekStart;
      } else if (this.current.weekEnd < this.current.weekStart) {
        this.getRef().error(this.$t("bilan.error.weeks"));
        return;
      }

      this.getRef().showLoader(this.$refs.form)
      bilanCondTravailService.create(this.current).then(data => {
        let msg = this.$t("bilan.added");
        this.$router.replace({ path: `/bilan/condtravail/${data.id}/details`, query: { msg: msg } });
      }).catch(e=>{
        this.getRef().hideLoader();
        this.getRef().error(this.$t("error." + e.response.data.error));
        this.submitted = false;
      });
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    }
  },
  watch: {
    uerListForCurrent() {
      if(!this.uerListForCurrent.some(uer => uer.id===this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if(!this.ceiListForCurrent.some(cei => cei.id===this.current.ceiId))
        this.current.ceiId = undefined;
    },
  },
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.create')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkInputNumber
              :min=1
              :max=53
              v-model="current.weekStart"
              :validator="v$.current.weekStart" 
              :label="$t(current.weekEnd ? 'bilan_cond_travail.week_start' : 'bilan_cond_travail.week')"
              class-name="col-md-3"/>
          <AkInputNumber
              :min=1
              :max=53
              v-model="current.weekEnd"
              :label="$t('bilan_cond_travail.week_end')"
              class-name="col-md-3"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="current.agerId"
                      :validator="v$.current.agerId"
                      :submitted=this.submitted
                      :options=this.agerList
                      :disabled=disableAger
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :submitted=this.submitted
                      :options=this.uerListForCurrent
                      :disabled=disableUer
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkDropdown :label="$t('cei_label')"
                      v-if="current.uerId"
                      v-model="current.ceiId"
                      :validator="v$.current.ceiId"
                      :submitted=this.submitted
                      :options=this.ceiListForCurrent
                      :disabled=disableCei
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
        </div>

        <h5>{{ $t('bilan_cond_travail.title1') }}</h5>

        <div class="form-row">
          <AkInputText
              placeholder=" "
              v-model="current.tauxRetourAstreinte"
              :validator="v$.current.tauxRetourAstreinte" 
              :label="$t('bilan_cond_travail.taux_retour_astreinte')"
              class-name="col-md-12"/>
        </div>
        
        <h5>{{ $t('bilan_cond_travail.title2') }}</h5>
        
        <div class="form-row">
          <AkInputText
              placeholder=" "
              v-model="current.nb1"
              :validator="v$.current.nb1" 
              :label="$t('bilan_cond_travail.nb1')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb2"
              :validator="v$.current.nb2" 
              :label="$t('bilan_cond_travail.nb2')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb3"
              :validator="v$.current.nb3" 
              :label="$t('bilan_cond_travail.nb3')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb4"
              :validator="v$.current.nb4" 
              :label="$t('bilan_cond_travail.nb4')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb5"
              :validator="v$.current.nb5" 
              :label="$t('bilan_cond_travail.nb5')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb6"
              :validator="v$.current.nb6" 
              :label="$t('bilan_cond_travail.nb6')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb7"
              :validator="v$.current.nb7" 
              :label="$t('bilan_cond_travail.nb7')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb8"
              :validator="v$.current.nb8" 
              :label="$t('bilan_cond_travail.nb8')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb9"
              :validator="v$.current.nb9" 
              :label="$t('bilan_cond_travail.nb9')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb10"
              :validator="v$.current.nb10" 
              :label="$t('bilan_cond_travail.nb10')"
              class-name="col-md-12"/>
        </div>

        <h5>{{ $t('bilan_cond_travail.title3') }}</h5>
        
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb11"
              :validator="v$.current.nb11" 
              :label="$t('bilan_cond_travail.nb11')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.meoActionRenforcee"
              :validator="v$.current.meoActionRenforcee" 
              :label="$t('bilan_cond_travail.meo_action_renforcee')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.meoOrgTravailSpec"
              :validator="v$.current.meoOrgTravailSpec" 
              :label="$t('bilan_cond_travail.meo_org_travail_spec')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.mepProgActivite"
              :validator="v$.current.mepProgActivite" 
              :label="$t('bilan_cond_travail.mep_prog_activite')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.orgParticuliereAvWe"
              :validator="v$.current.orgParticuliereAvWe" 
              :label="$t('bilan_cond_travail.org_particuliere_av_we')"
              class-name="col-md-12"/>
        </div>
        
        <div class="float-right">
          <button v-if=this.canCreateHealthcare() @click=create() class="btn btn-primary" style="margin-left: 10px">{{ $t('save') }}</button>
        </div>

      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>